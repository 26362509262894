import IconButton from "@mui/material/IconButton";
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import makeStyles from '@mui/styles/makeStyles';
import StylesProvider from '@mui/styles/StylesProvider';
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarProvider, useSnackbar } from "notistack";
import { HPSnackbarTheme } from "../../config/theme";

const useStyles = makeStyles(theme => ({
  success: { backgroundColor: "#0171ad" },
  error: { backgroundColor: "#d7410b" },
  warning: { backgroundColor: "#ffa500" },
  info: { backgroundColor: "#0171ad" }
}));

export function StyledSnackbarProvider({ children }) {
  const generateClassName = createGenerateClassName({
    productionPrefix: "iq"
  });

  const classes = useStyles();
  return (
    <StylesProvider generateClassName={generateClassName}>
      <SnackbarProvider
        {...HPSnackbarTheme}
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info
        }}
      >
        {children}
      </SnackbarProvider>
    </StylesProvider>
  );
}

// Must be imported at least once in the app to initialize the ref
let snackbarRef;
export const SnackbarUtilsConfigurator = () => {
  snackbarRef = useSnackbar();
  return null;
};

const variantFontColor = {
  success: "white",
  warning: "white",
  error: "white",
  info: "white"
};

const displayNotistack = {
  success(msg, options = {}) {
    this.toast(msg, { ...options, variant: "success" });
  },
  warning(msg, options = {}) {
    this.toast(msg, { ...options, variant: "warning" });
  },
  info(msg, options = {}) {
    this.toast(msg, { ...options, variant: "info" });
  },
  error(msg, options = {}) {
    this.toast(msg, { ...options, variant: "error" });
  },
  toast(
    msg,
    { variant, autoHideDuration = 6000, vertical = "top", horizontal = "left" }
  ) {
    let msgDuration = 30 * msg.length;
    let duration =
      msgDuration > autoHideDuration ? msgDuration : autoHideDuration;
    const message = (
      <Typography
        style={{
          color: variantFontColor[variant],
          wordBreak: "break-all",
          padding: "0 10px 0 0"
        }}
      >
        {msg}
      </Typography>
    );
    snackbarRef.enqueueSnackbar(message, {
      variant: variant,
      autoHideDuration: duration,
      anchorOrigin: { vertical, horizontal },
      action: key => (
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          style={{ position: "absolute", top: 0, bottom: 0, right: 0 }}
          onClick={() => {
            snackbarRef.closeSnackbar(key);
          }}
          size="large">
          <CloseIcon />
        </IconButton>
      )
    });
  }
};

export default displayNotistack;
