import makeStyles from "@mui/styles/makeStyles";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import {
  fetchConfigurationServicesTabFlag,
  fetchManageabilityServicesTabFlag,
  fetchMonitorAccessoriesTabFlag
} from "../Utility/utils";

const useStyles = makeStyles(theme => ({
  //adding custom variants
  success: { backgroundColor: "#0171ad" },
  error: { backgroundColor: "#d7410b" },
  warning: { backgroundColor: "#ffa500" },
  info: { backgroundColor: "#0171ad" }
}));

export default function ManageMyConfig(props) {
  const [loadView, setLoadView] = useState(true);
  const [state, setState] = useState(undefined);

  useEffect(() => {
    const token = Cookies.get("access_token");
    const userInfo = JSON.parse(Cookies.get("userInfo"));
    let bmiData = {};

    bmiData["country"] = "US";
    bmiData["configRegion"] = "NA";
    bmiData["currencyCode"] = "USD";
    bmiData["incoterms"] = "DDP";
    bmiData["userType"] = "INTERNAL";
    bmiData["companyId"] = "HPI";
    bmiData["productCategory"] = "compute";
    bmiData["isStandaloneAccess"] = true;
    bmiData["isDisplayProdCountry"] = true;
    bmiData["saveConfigurationLabel"] = "SAVE CONFIGURATION";
    bmiData["purchaseAgreement"] = "";
    bmiData["userEmail"] = userInfo.userEmail;
    bmiData["userId"] = userInfo.userId;
    const configRegion = bmiData["configRegion"] || "";
    const mDCPOrgID = bmiData["mDCPOrgID"] || "";
    const resellerId = bmiData["resellerId"] || "";
    const userType = bmiData["userType"] || "";
    const client = bmiData["client"] || "";
    const isStandaloneAccess = bmiData["isStandaloneAccess"] || "";
    const showPreApprovedConfigurationButton =
      (configRegion === "AP" || configRegion === "APJ") &&
      (mDCPOrgID !== "" || resellerId !== "") &&
      userType === "INTERNAL" &&
      client === "iqintegrated";
    const showManageMyConfigButton =
      isStandaloneAccess === false && userType === "PARTNER";
    const isListOfPLForPANumber =
      userType === "PARTNER" &&
      !isStandaloneAccess &&
      client === "iqintegrated";
    bmiData["isListOfPLForPANumber"] = isListOfPLForPANumber;
    bmiData["visibility"] = {
      showCustomBandedFlag: true,
      showPreApprovedConfigurationButton,
      showManageMyConfigButton,
      showConfigurationButton: true,
      showStartOverButton: true,
      showExportToPdf: true,
      showExportToExcel: true,
      showConfigurationID: true,
      showConfigurationName: true,
      showAccessType: true,
      showMonitorsAccessoriesTab: fetchMonitorAccessoriesTabFlag(
        userType,
        configRegion
      ),
      showConfigurationServicesTab: fetchConfigurationServicesTabFlag(
        userType,
        configRegion
      ),
      showManageabilityServicesTab: fetchManageabilityServicesTabFlag(
        userType,
        configRegion
      )
    };
    //Setting configJWT as bmiData Object propoerty which will be consumed in one-config-package
    //passing token from the Cookie
    bmiData["configJWT"] = token;
    setState(bmiData);
  }, []);

  useEffect(() => {
    const loadDefaultConfig = data => {
      if (data && loadView) {
        if (document.getElementById("manageMyConfig") !== null) {
          window.oneConfig({
            targetID: document.getElementById("manageMyConfig"),
            startingPoint: "oneconfig-manage-myconfig",
            payload: data
          });
        }
        setLoadView(false);
      }
    };
    if (state) {
      let data = JSON.stringify(state);
      loadDefaultConfig(data);
    }
  }, [state, loadView]);

  return <div id="manageMyConfig"></div>;
}
