// import defaultColumns from "./columns/defaultColumns";
// import distributorColumnsHT from "./columns/distributorColumnsHT";
// import distributorColumnsLT from "./columns/distributorColumnsLT";
// import hpInternalDirectColumnsHT from "./columns/hpInternalDirectColumnsHT";
// import hpInternalDirectColumnsLT from "./columns/hpInternalDirectColumnsLT";
// import hpInternalIndirectColumnsHT from "./columns/hpInternalIndirectColumnsHT";
// import hpInternalIndirectColumnsLT from "./columns/hpInternalIndirectColumnsLT";
// import ProductDealBTOColumns from "./lightpath/ProductDealBTOColumns";
// import productSelectorBaseUnitColumns from "./productselector/productSelectorBaseUnitColumns";
// import productSelectorColumns from "./productselector/productSelectorColumns";
import manageMyConfigColumns from "./managemyconfig/manageMyConfigColumns";
// import resellerColumnsHT from "./columns/resellerColumnsHT";
// import resellerColumnsLT from "./columns/resellerColumnsLT";

export default {
  // defaultColumns,
  // hpInternalDirectColumnsHT,
  // hpInternalIndirectColumnsHT,
  // resellerColumnsHT,
  // distributorColumnsHT,
  // resellerColumnsLT,
  // distributorColumnsLT,
  // hpInternalDirectColumnsLT,
  // hpInternalIndirectColumnsLT,
  // productSelectorColumns,
  // ProductDealBTOColumns,
  // productSelectorBaseUnitColumns,
  manageMyConfigColumns
};
