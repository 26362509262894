import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";

// import { SEARCH_CONFIG_ID } from '../../GraphQL/Queries';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  pcrHeader: {
    gridArea: "header",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    padding: "5px 20px",
    lineHeight: "1.1em",
    backgroundColor: "white",
    position: "fixed",
    left: "0",
    right: "0",
    top: "0",
    zIndex: "999",
    boxShadow: "0 1px 3px steelblue"
  },
  pcrHeaderUser: {
    textDecoration: "none",
    fontSize: "16px",
    marginTop: "29px",
    color: "#5a5a5a !important",
    zIndex: "0",
    display: "flex",
    position: "absolute",
    right: "15px",
    top: "-15px"
  },
  papr: {
    width: 250,
    height: 36,
    marginTop: "3px",
    marginLeft: "215px",
    borderRadius: "1px",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)"
  },

  inputBase: {
    width: "80%",
    fontSize: "16px",
    bottom: "5px"
  },

  inputRoot: {
    color: "inherit"
  },

  treeAdmin: {
    marginLeft: "6px",
    fontSize: "13px",
    fontWeight: "100 !important"
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  },

  gridRight: {
    marginLeft: "15px",
    marginRight: "15px"
  },

  expandIcon: {
    marginTop: "-2px"
  }
}));

const Header = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { userInfo } = useSelector(state => state.user);
  console.log("userInfo", userInfo);
  const { onExit } = props;
  const [searchValue, setSearchValue] = useState();
  const location = useLocation();

  const searchValueHandler = eve => {
    setSearchValue(eve.target.value);
  };

  const searchConfigIdDetails = () => {
    // if(searchValue ){
    //     criteriaSearch({
    //         variables : {
    //             configId : searchValue,
    //             originatingAsset:"OCA-Config"
    //         }
    //     })
    // }
  };

  const onConfigSearchHandler = () => {
    // if(searchValue.length >=8){
    //     searchConfigIdDetails();
    //     setSearchValue('');
    //     props.history.push('/dashboard/searchconfigid')
    // }
  };

  const enterkeyHandler = e => {
    if (e.keyCode == 13) {
      onConfigSearchHandler();
    }
  };

  // const [ criteriaSearch ] = useLazyQuery(SEARCH_CONFIG_ID, {
  //     fetchPolicy: "no-cache",
  //     onCompleted({ criteriaSearchConfig }) {
  //         console.log(criteriaSearchConfig)
  //       dispatch({
  //         type: actionTypes.SEARCH_CRITERIA_DETAILS,
  //         payload: criteriaSearchConfig
  //       });
  //     },
  //     onError({ graphQLErrors, networkError }) {
  //       if (graphQLErrors) {
  //         console.log(graphQLErrors)
  //       }
  //       if (networkError) {
  //         console.log(networkError)
  //       }
  //     }
  //   });

  let logo = (
    <img
      src={
        process.env.PUBLIC_URL + location.pathname === "/dashboard"
          ? "/oneConfigLogoMain.svg"
          : "/oneConfigLogoSecondary.svg"
      }
      style={{
        height: "48px"
      }}
      alt="pcr-header-logo-img"
    />
  );
  let title = "One Config IQ";
  const notPartner = userInfo?.userType?.toLowerCase() !== "partner";
  if (notPartner) {
    logo = <Link to="/dashboard">{logo}</Link>;
    title = "One Config IQ Simulation";
  }

  return (
    <Grid
      className={classes.pcrHeader}
      style={{
        height: "64px",
        background:
          location.pathname === "/dashboard"
            ? "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))"
            : "white"
      }}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid container direction="row" alignItems="center">
        <Grid>{logo}</Grid>
        <Grid style={{ paddingLeft: "24px" }}>
          <Typography
            variant="h5"
            style={{
              color: location.pathname === "/dashboard" ? "white" : "black",
              fontSize: "28px"
            }}
          >
            {title}
          </Typography>
        </Grid>

        {userInfo && (
          <div>
            {/* <Grid>
                        <div component="form" aria-setsize className={classes.papr}>
                            <IconButton type="submit" aria-label="search" onClick={onConfigSearchHandler}  >
                                <SearchIcon style={{ marginTop: '-5px' }} />
                            </IconButton>
                            <InputBase
                                className={classes.inputBase}
                                placeholder="Search Config ID"
                                value={searchValue}
                                onKeyDown={enterkeyHandler}
                                onChange={searchValueHandler}
                            />
                        </div>
                    </Grid> */}

            <Grid className={classes.pcrHeaderUser}>
              <Grid
                className="pcr-header-profile-info"
                style={{
                  color:
                    location.pathname === "/dashboard" ? "white" : "inherit"
                }}
              >
                <div className="pcr-header-username">
                  {userInfo?.userEmail || "Admin"}
                </div>
                <Grid container direction="row" justifyContent="flex-end">
                  <Grid>
                    <Typography className={classes.treeAdmin}>
                      Admin User
                    </Typography>
                  </Grid>
                  <Grid>
                    <ExpandMoreIcon
                      // color='disabled'
                      className={classes.expandIcon}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <span
                className="pcr-header-logout"
                onClick={() => {
                  onExit();
                }}
              >
                <Grid container style={{ flexWrap: "unset" }}>
                  {notPartner && (
                    <Grid item xs={12} style={{ margin: "0px 6px" }}>
                      <img
                        src={
                          location.pathname === "/dashboard"
                            ? `${process.env.REACT_APP_SVG_BASE_URL}/Header/user_profile_3.svg`
                            : `${process.env.REACT_APP_SVG_BASE_URL}/Header/user_profile2.svg`
                        }
                      />
                      {/* <img src={user_profile} className={classes.gridRight} /> */}
                    </Grid>
                  )}
                </Grid>
              </span>
            </Grid>
          </div>
        )}
      </Grid>
      {props.children}
    </Grid>
  );
};

export default withRouter(Header);
