import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
//fetching token from props which are being passed through protected routes
//importing functions which will handle the tab visibility flags based on user type and config regions
import {
  fetchConfigurationServicesTabFlag,
  fetchManageabilityServicesTabFlag,
  fetchMonitorAccessoriesTabFlag
} from "../Utility/utils";
export default function ProductSelectorContainer() {
  const [loadView, setLoadView] = useState(true);
  //import the token from cookie
  // const token = Cookie.get("access_token");

  let bmiData = JSON.parse(localStorage.getItem("bmiData"));
  const token = Cookies.get("access_token");
  useEffect(() => {
    const configRegion = bmiData["configRegion"] || "";
    const mDCPOrgID = bmiData["mDCPOrgID"] || "";
    const resellerId = bmiData["resellerId"] || "";
    const userType = bmiData["userType"] || "";
    const client = bmiData["client"] || "";
    const isStandaloneAccess = bmiData["isStandaloneAccess"] || "";
    const showPreApprovedConfigurationButton =
      (configRegion === "AP" || configRegion === "APJ") &&
      (mDCPOrgID !== "" || resellerId !== "") &&
      userType === "INTERNAL" &&
      client === "iqintegrated";
    // const showManageMyConfigButton =
    // isStandaloneAccess === false && userType === "PARTNER";
    if (userType.toLowerCase() === "partner") {
      document.title = "One Config IQ";
    }
    bmiData["saveConfigurationLabel"] =
      isStandaloneAccess === false && client === "iqintegrated"
        ? "ADD TO QUOTE"
        : "SAVE CONFIGURATION";
    bmiData["routeToMarket"] = bmiData.routeToMarket || "Direct";
    const isListOfPLForPANumber =
      userType === "PARTNER" &&
      !isStandaloneAccess &&
      client === "iqintegrated";
    bmiData["isListOfPLForPANumber"] = isListOfPLForPANumber;
    bmiData["visibility"] = {
      showCustomBandedFlag: true,
      showPreApprovedConfigurationButton,
      showManageMyConfigButton: true,
      showConfigurationButton: true,
      showStartOverButton: true,
      showExportToPdf: true,
      showExportToExcel: true,
      showConfigurationID: true,
      showConfigurationName: true,
      showAccessType: true,
      showStartingPointInformation:
        userType.toUpperCase() === "PARTNER" ? false : true,
      showMonitorsAccessoriesTab: fetchMonitorAccessoriesTabFlag(
        userType,
        configRegion
      ),
      showConfigurationServicesTab: fetchConfigurationServicesTabFlag(
        userType,
        configRegion
      ),
      showManageabilityServicesTab: fetchManageabilityServicesTabFlag(
        userType,
        configRegion
      )
    };
    //Setting configJWT as bmiData Object propoerty which will be consumed in one-config-package
    //passing token from the Cookie
    bmiData["configJWT"] = token;

    let data = JSON.stringify(bmiData);

    loadDefaultConfig(data);
  }, []);
  const loadDefaultConfig = data => {
    if (data && loadView) {
      if (document.getElementById("productSelectorContainer") !== null) {
        window.oneConfig({
          targetID: document.getElementById("productSelectorContainer"),
          startingPoint: "product-selector",
          payload: data
        });
      }
      setLoadView(false);
    }
  };
  return <div id="productSelectorContainer"></div>;
}
