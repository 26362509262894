import { useLazyQuery } from "@apollo/client";
import { Box, Container, Paper, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { FETCH_USER_DETAILS } from "../GraphQL";
import BmiSimulationModel from "./DashboardModels/BmiSimulationModel";

const Dashboard = props => {
  const [open, setOpen] = React.useState(false);
  const [hover1, setHover1] = React.useState(false);
  const [hover2, setHover2] = React.useState(false);
  const [hover3, setHover3] = React.useState(false);
  const [hover4, setHover4] = React.useState(false);
  const [country, setCountry] = useState("US");
  const [region, setRegion] = useState("NA");

  const [getUserDetails] = useLazyQuery(FETCH_USER_DETAILS, {
    fetchPolicy: "no-cache",
    onCompleted({ fetchUserDetails }) {
      fetchUserDetails[0]?.countryCode &&
        setCountry(fetchUserDetails[0]?.countryCode);
      fetchUserDetails[0]?.defaultRegionCd &&
        setRegion(fetchUserDetails[0]?.defaultRegionCd);
    }
  });

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles(theme => ({
    card: {
      height: "270px",
      marginTop: "10px",
      "&:hover": {
        color: "#0096D6",
        marginTop: "0px"
      }
    },
    iconSection: {
      textAlign: "center",
      width: "100%",
      paddingTop: "42px"
    },
    icon: {
      width: "106px",
      height: "106px"
    },
    textSection: {
      paddingTo: "18px",
      lineHeight: "36.85px",
      fontFamily: "HP Simplified",
      fontWeight: 400,
      fontSize: 28,
      textAlign: "center",
      width: "100%"
    },
    text: {
      width: "90%",
      margin: "auto"
    }
  }));

  let data = {
    country: country,
    configRegion: region,
    currencyCode: "USD",
    incoterms: "DDP",
    userId: "chethana chandrayan",
    userType: "",
    userEmail: "chethana.chandrayan@hp.com",
    companyId: "HPI",
    productCategory: "compute",
    isStandaloneAccess: true,
    isDisplayProdCountry: true
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConfigInternal = () => {
    const userInfo = JSON.parse(Cookies.get("userInfo"));
    data["userEmail"] = userInfo.userEmail;
    data["userId"] = userInfo.userId;
    data["userType"] = "INTERNAL";
    data["companyId"] = "HPI";
    localStorage.setItem("bmiData", JSON.stringify(data));
    localStorage.setItem("isStandalone", true);
    window.open("/productselection", "_SELF");
    //props.history.push("/productselection");
    //setOpenInternalModel(false);
  };

  const handleOpenConfigPartner = () => {
    const userInfo = JSON.parse(Cookies.get("userInfo"));
    data["userEmail"] = userInfo.userEmail;
    data["userId"] = userInfo.userId;
    data["userType"] = "PARTNER";
    data["companyId"] = "HPI";
    data["routeToMarket"] = "Indirect";

    localStorage.setItem("bmiData", JSON.stringify(data));
    localStorage.setItem("isStandalone", true);
    window.open("/productselection", "_SELF");
    //props.history.push("/productselection");
    //setOpenPartnerModel(true);
  };
  const handleOpenManageMyConfig = () => {
    window.open("/managemyconfig", "_SELF");
  };

  const handleHover1 = val => {
    if (hover1 !== val) {
      setHover1(val);
    }
  };

  const handleHover2 = val => {
    if (hover2 !== val) {
      setHover2(val);
    }
  };

  const handleHover3 = val => {
    if (hover3 !== val) {
      setHover3(val);
    }
  };

  const handleHover4 = val => {
    if (hover4 !== val) {
      setHover4(val);
    }
  };

  const classes = useStyles();

  return (
    <Box
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        height: "100%",
        width: "100%",
        backgroundImage: "url(" + `${process.env.REACT_APP_SVG_BASE_URL}/DashboardAssets/Bg_image.jpg` + ")"
      }}
    >
      <Container
        maxWidth="lg"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          height: "100%"
        }}
      >
        <Box display="flex" alignItems="flex-end" style={{ height: "100%" }}>
          <Box style={{ width: "100%", height: matches ? "296px" : "auto" }}>
            <Grid container xs={12} spacing={2} style={{ height: "100%" }}>
              <Grid item xs={6} md={3}>
                <Paper
                  className={classes.card}
                  onClick={handleOpenConfigInternal}
                  onMouseEnter={() => handleHover1(true)}
                  onMouseLeave={() => handleHover1(false)}
                >
                  <Box className={classes.iconSection}>
                    <img
                      src={
                        hover1
                          ? `${process.env.REACT_APP_SVG_BASE_URL}/DashboardAssets/icon_1_hover.svg`
                          : `${process.env.REACT_APP_SVG_BASE_URL}/DashboardAssets/icon_1_normal.svg`
                      }
                      className={classes.icon}
                      alt="savedConfigInternalIcon"
                    />
                  </Box>
                  <Box className={classes.textSection}>
                    <Box className={classes.text}>
                      Create Saved CONFIG as Internal
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6} md={3}>
                <Paper
                  className={classes.card}
                  onClick={handleOpenConfigPartner}
                  onMouseEnter={() => handleHover2(true)}
                  onMouseLeave={() => handleHover2(false)}
                >
                  <Box className={classes.iconSection}>
                    <img
                      src={
                        hover2
                          ? `${process.env.REACT_APP_SVG_BASE_URL}/DashboardAssets/icon_2_hover.svg`
                          : `${process.env.REACT_APP_SVG_BASE_URL}/DashboardAssets/icon_2_normal.svg`
                      }
                      className={classes.icon}
                      alt="savedConfigPackageIcon"
                    />
                  </Box>
                  <Box className={classes.textSection}>
                    <Box className={classes.text}>
                      Create Saved CONFIG as Partner
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6} md={3}>
                <Paper
                  className={classes.card}
                  onClick={handleOpen}
                  onMouseEnter={() => handleHover3(true)}
                  onMouseLeave={() => handleHover3(false)}
                >
                  <Box className={classes.iconSection}>
                    <img
                      src={
                        hover3
                          ? `${process.env.REACT_APP_SVG_BASE_URL}/DashboardAssets/icon_3_hover.svg`
                          : `${process.env.REACT_APP_SVG_BASE_URL}/DashboardAssets/icon_3_normal.svg`
                      }
                      className={classes.icon}
                      alt="bmiToolSimulationIcon"
                    />
                  </Box>
                  <Box className={classes.textSection}>
                    <Box className={classes.text}>BMI Tool Simulation</Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6} md={3}>
                <Paper
                  className={classes.card}
                  onClick={handleOpenManageMyConfig}
                  onMouseEnter={() => handleHover4(true)}
                  onMouseLeave={() => handleHover4(false)}
                >
                  <Box className={classes.iconSection}>
                    <img
                      src={
                        hover4
                          ? `${process.env.REACT_APP_SVG_BASE_URL}/DashboardAssets/icon_4_hover.svg`
                          : `${process.env.REACT_APP_SVG_BASE_URL}/DashboardAssets/icon_4_normal.svg`
                      }
                      className={classes.icon}
                      alt="manageMyConfigIcon"
                    />
                  </Box>
                  <Box className={classes.textSection}>
                    <Box className={classes.text}>Manage My Config</Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* <Grid container xs={12}>
                
            </Grid> */}

        {/* BMI Simulation Model */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {/* <LandingPage /> */}
          <BmiSimulationModel onCancel={handleClose} history={props.history} />
        </Modal>
      </Container>
    </Box>
  );
};

export default withRouter(Dashboard);
